import { EventUrlEnum } from 'data/enums/EventUrlEnum';

export const baseClientPath = '/client';
const applyBaseUrl = (url) => `${baseClientPath}/${url}`;

// Invites
export const invitesPath = applyBaseUrl('invites');

export const acceptInvitePath = (inviteId: string) => `${invitesPath}/${inviteId}/accept`;

// Records
export const recordsPath = applyBaseUrl('records');

export const recordsActivePath = `${recordsPath}/active`;
export const recordsArchivedPath = `${recordsPath}/archived`;

export const clientRecordInfoPath = (recordId) => `${recordsPath}/client/${recordId}`;
export const affiliatedRecordInfoPath = (recordId) => `${recordsPath}/affiliated/${recordId}`;

export const clientRecordTrajectoriesPath = (recordId) => `${clientRecordInfoPath(recordId)}/trajectories`;
export const clientRecordTrajectoryPath = (recordId, trajectoryId) =>
  `${clientRecordTrajectoriesPath(recordId)}/${trajectoryId}`;
export const clientRecordTrajectoryEventPath = (recordId, trajectoryId, eventType, eventId) =>
  `${clientRecordTrajectoryPath(recordId, trajectoryId)}/${eventType}/${eventId}`;
export const clientRecordTrajectoryEventDocumentPath = (recordId: string, eventType: string, documentId: string) =>
  `${clientRecordInfoPath(recordId)}/documents/${eventType}/${documentId}`;
export const clientRecordTrajectoryUncompletedQuestionnairePath = (recordId, trajectoryId, eventId, questionnaireId) =>
  `${clientRecordTrajectoryEventPath(
    recordId,
    trajectoryId,
    EventUrlEnum.session,
    eventId,
  )}/uncompleted/${questionnaireId}`;
export const clientRecordTrajectoryCompletedQuestionnairePath = (recordId, trajectoryId, eventId, questionnaireId) =>
  `${clientRecordTrajectoryEventPath(
    recordId,
    trajectoryId,
    EventUrlEnum.session,
    eventId,
  )}/completed/${questionnaireId}`;
export const clientRecordTrajectoryQuestionnaireReportPath = (
  recordId,
  trajectoryId,
  eventId,
  questionnaireId,
  reportId,
) =>
  `${clientRecordTrajectoryCompletedQuestionnairePath(
    recordId,
    trajectoryId,
    eventId,
    questionnaireId,
  )}/report/${reportId}`;

export const clientRecordDetailsPath = (recordId: string) => `${clientRecordInfoPath(recordId)}/details`;
export const clientRecordDetailsPersonalInfoPath = (recordId: string) =>
  `${clientRecordDetailsPath(recordId)}/personal-info`;
export const clientRecordDetailsHealthInfoPath = (recordId: string) =>
  `${clientRecordDetailsPath(recordId)}/health-info`;
export const clientRecordDetailsAddressInfoPath = (recordId: string) =>
  `${clientRecordDetailsPath(recordId)}/address-info`;
export const clientRecordDetailsTherapistsPath = (recordId: string) =>
  `${clientRecordDetailsPath(recordId)}/therapists`;
export const clientRecordDetailsAffiliatesPath = (recordId: string) =>
  `${clientRecordDetailsPath(recordId)}/affiliates`;
export const clientRecordDocumentsPath = (recordId) => `${clientRecordInfoPath(recordId)}/documents`;
export const clientRecordDocumentDetailsPath = (recordId, documentId) =>
  `${clientRecordInfoPath(recordId)}/documents/${documentId}`;

export const affiliatedRecordTrajectoriesPath = (recordId) => `${affiliatedRecordInfoPath(recordId)}/trajectories`;
export const affiliatedRecordTrajectoryPath = (recordId, trajectoryId) =>
  `${affiliatedRecordTrajectoriesPath(recordId)}/${trajectoryId}`;
export const affiliatedRecordTrajectoryEventPath = (recordId, trajectoryId, eventType, eventId) =>
  `${affiliatedRecordTrajectoryPath(recordId, trajectoryId)}/${eventType}/${eventId}`;
export const affiliatedRecordTrajectoryUncompletedQuestionnairePath = (
  recordId,
  trajectoryId,
  eventId,
  questionnaireId,
) =>
  `${affiliatedRecordTrajectoryEventPath(
    recordId,
    trajectoryId,
    EventUrlEnum.session,
    eventId,
  )}/uncompleted/${questionnaireId}`;
export const affiliatedRecordTrajectoryCompletedQuestionnairePath = (
  recordId,
  trajectoryId,
  eventId,
  questionnaireId,
) =>
  `${affiliatedRecordTrajectoryEventPath(
    recordId,
    trajectoryId,
    EventUrlEnum.session,
    eventId,
  )}/completed/${questionnaireId}`;
export const affiliatedRecordTrajectoryQuestionnaireReportPath = (
  recordId,
  trajectoryId,
  eventId,
  questionnaireId,
  reportId,
) =>
  `${affiliatedRecordTrajectoryCompletedQuestionnairePath(
    recordId,
    trajectoryId,
    eventId,
    questionnaireId,
  )}/report/${reportId}`;

export const affiliatedRecordDetailsPath = (recordId) => `${affiliatedRecordInfoPath(recordId)}/details`;
export const affiliatedRecordDetailsTherapistsPath = (recordId) =>
  `${affiliatedRecordDetailsPath(recordId)}/therapists`;
export const affiliatedRecordDocumentsPath = (recordId) => `${affiliatedRecordInfoPath(recordId)}/documents`;
export const affiliatedRecordDocumentDetailsPath = (recordId, documentId) =>
  `${affiliatedRecordInfoPath(recordId)}/documents/${documentId}`;

// My-system
export const mySystemPath = applyBaseUrl('my-system');

export const mySystemFamilyPath = `${mySystemPath}/family`;
export const mySystemInvolvedPeoplePath = `${mySystemPath}/involved-persons`;
export const mySystemTherapistsPath = `${mySystemPath}/therapists`;
export const mySystemLeadsPath = `${mySystemPath}/leads`;
export const mySystemLeadsTrajectoryPath = (leadId) => `${mySystemLeadsPath}/${leadId}`;

export const mySystemLeadsTrajectorySessionPath = (leadId, sessionId) =>
  `${mySystemLeadsTrajectoryPath(leadId)}/event/${sessionId}`;

export const mySystemPersonPath = (personId) => `${mySystemInvolvedPeoplePath}/${personId}`;
export const mySystemFamilyPersonPath = `${mySystemFamilyPath}/add`;
export const mySystemFamilyPersonEditPath = (memberId) => `${mySystemFamilyPath}/${memberId}`;

// Documents
export const filesPath = applyBaseUrl('files');

export const documentsPath = `${filesPath}/documents`;
export const uploadsPath = `${filesPath}/uploads`;
export const reportsPath = `${filesPath}/reports`;

/**
 *
 * @param {string} documentId
 * @return string
 */
export const documentPath = (documentId) => `${documentsPath}/${documentId}`;
/**
 *
 * @param {string} uploadId
 * @return string
 */
export const uploadPath = (uploadId) => `${uploadsPath}/${uploadId}`;
/**
 *
 * @param {string} reportId
 * @return string
 */
export const reportPath = (reportId) => `${reportsPath}/${reportId}`;
/**
 *
 * @param {string} path
 * @param {string} query
 * @return string
 */
export const queryPath = (path, query) => `${path}?${query}`;

// Questionnaires
export const questionnairesPath = applyBaseUrl('trajectories');
export const questionnairesCompleted = (sessionId, questionnaireId) =>
  `${questionnairesPath}/session/${sessionId}/complete/${questionnaireId}`;
export const questionnairesUncompleted = (questionnaireId) => `${questionnairesPath}/uncomplete/${questionnaireId}`;
export const questionnairesActive = `${questionnairesPath}/active`;
export const questionnairesEnded = `${questionnairesPath}/ended`;
export const questionnairesEndedItem = (trajectoryId) => `${questionnairesEnded}/${trajectoryId}`;
export const questionnairesEndedSession = (trajectoryId, sessionId) =>
  `${questionnairesEnded}/${trajectoryId}/${sessionId}`;
export const questionnairesActiveSession = (sessionId) => `${questionnairesActive}/event/${sessionId}`;
export const questionnairesForAffiliates = (trajectoryId, sessionId) =>
  `${questionnairesActive}/as-affiliate/${trajectoryId}/${sessionId}`;

// Profile
export const profilePath = applyBaseUrl('profile');

// Chat
export const chatPath = `${applyBaseUrl('chat')}`;
export const chatRoomPath = (roomId: string) => `${chatPath}/${roomId}`;

// MODULES
export const modulesPath = applyBaseUrl('modules');
export const modulesDetailsPath = (moduleId: string) => `${modulesPath}/${moduleId}`;
export const modulesBlendedCarePath = `${modulesPath}/blended-care`;

// FORBIDDEN
export const forbiddenErrorPath = applyBaseUrl(`forbidden`);
