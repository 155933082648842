import { api } from 'data';

import { File, DocumentFile } from 'data/types';

export const uploadFilePublic = (formData: FormData, progressFn?: (percent: number) => void): Promise<File> =>
  api.post(`/files/public`, formData, {
    onUploadProgress: (progressEvent) => {
      if (progressFn) {
        const total = progressEvent.total || 1;
        progressFn(Math.round((progressEvent.loaded * 100) / total));
      }
    },
  });

export const uploadFile = (userId: string, body: FormData, params?: string): Promise<File> =>
  api.post(`/files/users/${userId}${params ? `?${params}` : ''}`, body);

export const deleteFile = (fileId: string): Promise<void> => api.delete(`/files/${fileId}`);

export const deleteFileFromSession = (fileId: string): Promise<void> => api.delete(`/files/${fileId}/resource/session`);

export const deleteFileFromActivity = (fileId: string): Promise<void> =>
  api.delete(`/files/${fileId}/resource/activity`);

export const shareFile = (fileId: string, userId: string): Promise<void> =>
  api.post(`/files/${fileId}/connections/${userId}`);

export const unshareFile = (fileId: string, userId: string): Promise<void> =>
  api.delete(`/files/${fileId}/connections/${userId}`);

export const downloadFile = (fileId: string): Promise<Blob> => api.get(`/files/${fileId}`, { responseType: 'blob' });

export const downloadPublicFile = (token: string): Promise<Blob> =>
  api.get(`/files/${token}/public`, { responseType: 'blob' });

export const downloadSessionFile = (fileId: string): Promise<Blob> =>
  api.get(`/files/${fileId}/download/resource/session`, { responseType: 'blob' });

export const downloadActivityFile = (fileId: string): Promise<Blob> =>
  api.get(`/files/${fileId}/download/resource/activity`, { responseType: 'blob' });

export const downloadTaskFile = (fileId: string): Promise<Blob> =>
  api.get(`/files/${fileId}/download/resource/task`, { responseType: 'blob' });

export const downloadFileLink = (fileId: string): Promise<string> => api.get(`/files/${fileId}/url/record`);

export const fetchActivityFile = (fileId: string): Promise<DocumentFile> =>
  api.get(`/files/${fileId}/resource/activity`);

export const fetchActivityFileLink = (fileId: string): Promise<string> => api.get(`/files/${fileId}/url/activity`);

export const fetchNoteFile = (fileId: string, noteId: string): Promise<DocumentFile> =>
  api.get(`/files/${fileId}/resource/note/${noteId}`);

export const fetchNoteFileLink = (fileId: string): Promise<string> => api.get(`/files/${fileId}/url/note`);

export const downloadNoteFile = (fileId: string): Promise<Blob> =>
  api.get(`/files/${fileId}/download/resource/note`, { responseType: 'blob' });

export const fetchPortfolioPublicFileLink = (fileId: string, resourceId: string): Promise<string> =>
  api.get(`/files/${fileId}/url/resource/portfolioBlock/${resourceId}/public`);

export const fetchQuestionnaireManual = (fileId: string): Promise<DocumentFile> =>
  api.get(`/files/${fileId}/resource/questionnaire`);

export const fetchDocumentFile = (fileId: string): Promise<Blob> => api.get(`/files/${fileId}/resource/document`);

export const fetchDocumentFileLink = (fileId: string): Promise<string> => api.get(`/files/${fileId}/url/document`);

export const fetchTemplateFile = (fileId: string): Promise<Blob> => api.get(`/files/${fileId}/resource/template`);

export const fetchTemplateFileLink = (fileId: string): Promise<string> => api.get(`/files/${fileId}/url/template`);

export const fetchPersonalNotesFile = (fileId: string): Promise<DocumentFile> =>
  api.get(`/files/${fileId}/resource/record`);

export const deletePersonalNotesFile = (fileId: string): Promise<void> =>
  api.delete(`/files/${fileId}/resource/record`);
