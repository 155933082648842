import { api } from 'data';
import { ActivityType } from 'data/enums';
import {
  CreateInteractionActivity,
  InteractionActivityEntity,
  ExpandedInteractionActivity,
  ArrayResponseType,
  EditSessionFormValues,
} from 'data/types';

export const createInteractionActivity = (
  trajectory: string,
  type: ActivityType,
  body: CreateInteractionActivity,
): Promise<InteractionActivityEntity> => api.post(`/activities/types/${type}/trajectory/${trajectory}`, body);

export const fetchInteractionActivities = (filters: string): Promise<ArrayResponseType<ExpandedInteractionActivity>> =>
  api.get(`/activities?${filters}`);

export const fetchInteractionActivitiesReport = (filters: string): Promise<Blob> =>
  api.get(`/activities/reports/download?${filters}`, { responseType: 'blob' });
export const fetchConventionsReport = (userId: string, filters: string): Promise<Blob> =>
  api.get(`/metrics/users/${userId}/convention/export?${filters}`, { responseType: 'blob' });

export const fetchInteractionActivity = (activityId: string): Promise<InteractionActivityEntity> =>
  api.get(`/activities/${activityId}`);

export const removeInteractionActivity = (activityId: string): Promise<void> => api.delete(`/activities/${activityId}`);

export const patchInteractionActivity = (
  activityId: string,
  body: Partial<EditSessionFormValues>,
): Promise<InteractionActivityEntity> => api.patch(`/activities/interaction/${activityId}`, body);
