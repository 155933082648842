import { api } from 'data';
import { Trajectory, ArrayResponseType, CreateTrajectory, TrajectoryEntity, EditTrajectory } from 'data/types';

export const fetchRecordClientTrajectories = (
  recordId: string,
): Promise<Omit<ArrayResponseType<Trajectory>, 'total'>> => api.get(`/trajectories/records/${recordId}/patients`);

export const fetchRecordClientPastTrajectories = (
  recordId: string,
  params: string,
): Promise<Omit<ArrayResponseType<Trajectory>, 'total'>> =>
  api.get(`/trajectories/records/${recordId}/patients?${params}`);

export const fetchAffiliatedRecordTrajectories = (
  recordId: string,
): Promise<Omit<ArrayResponseType<Trajectory>, 'total'>> => api.get(`/trajectories/records/${recordId}/affiliates`);

export const fetchAffiliatedRecordPastTrajectories = (
  recordId: string,
  params: string,
): Promise<Omit<ArrayResponseType<Trajectory>, 'total'>> =>
  api.get(`/trajectories/records/${recordId}/affiliates?${params}`);

export const fetchRecordTrajectories = (recordId: string, query?: string): Promise<ArrayResponseType<Trajectory>> =>
  api.get(`/trajectories/records/${recordId}?${query}`);

export const createTrajectory = (recordId: string, values: CreateTrajectory): Promise<TrajectoryEntity> =>
  api.post(`/trajectories/records/${recordId}`, values);

export const editTrajectory = (trajectoryId: string, values: EditTrajectory): Promise<TrajectoryEntity> =>
  api.patch(`/trajectories/${trajectoryId}`, values);

export const fetchTrajectory = (trajectoryId?: string): Promise<Trajectory> => api.get(`/trajectories/${trajectoryId}`);

export const fetchTrajectoryClient = (trajectoryId: string): Promise<Trajectory> =>
  api.get(`/trajectories/${trajectoryId}/patients`);

export const fetchAllRecordTrajectories = (recordId: string, params: string): Promise<ArrayResponseType<Trajectory>> =>
  api.get(`/trajectories/records/${recordId}/all?${params}`);
