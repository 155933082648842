import { api } from 'data';
import {
  ArrayResponseType,
  CreateIntake,
  CreateIntakeFormQuestion,
  CreateOrganisationForm,
  Intake,
  IntakeCSVData,
  IntakeFormQuestion,
  IntakePublicFormQuestions,
  OrganisationForm,
  ReferenceQuestionType,
} from 'data/types';

export const fetchIntakes = (organisationId: string, params?: string): Promise<ArrayResponseType<Intake>> =>
  api.get(`intakes/organisations/${organisationId}?${params}`);

export const fetchOrganisationForm = (organisationId: string): Promise<OrganisationForm> =>
  api.get(`forms/organisations/${organisationId}`);

export const fetchPublicFormQuestions = (token: string, limit = 1000): Promise<IntakePublicFormQuestions> =>
  api.get(`forms/tokens/${token}/questions?limit=${limit}`);

export const fetchOrgsanisationFormQuestions = (
  formId: string,
  limit = 1000,
): Promise<ArrayResponseType<IntakeFormQuestion>> => api.get(`forms/${formId}/questions?limit=${limit}`);

export const answerPublicIntakeForm = (token: string, body: CreateIntake): Promise<Intake> =>
  api.post(`intakes/tokens/${token}`, body);

export const createOrganisationForm = (
  organisationId: string,
  body: CreateOrganisationForm,
): Promise<OrganisationForm> => api.post(`forms/organisations/${organisationId}`, body);

export const createOrganisationFormQuestions = (
  formId: string,
  body: { questions: CreateIntakeFormQuestion[] },
): Promise<ArrayResponseType<IntakeFormQuestion>> => api.post(`forms/${formId}/questions`, body);

export const deleteOrganisationFormQuestions = (formId: string, body: { ids: string[] }): Promise<void> =>
  api.delete(`forms/${formId}/questions`, { data: body });

export const updateOrganisationFormQuestions = (
  formId: string,
  body: { questions: CreateIntakeFormQuestion[] },
): Promise<ArrayResponseType<IntakeFormQuestion>> => api.patch(`forms/${formId}/questions`, body);

export const answerIntakeFormPreview = (organisationId: string, body: CreateIntake): Promise<Intake> =>
  api.post(`intakes/organisations/${organisationId}`, body);

export const fetchIntake = (intakeId: string): Promise<Intake> => api.get(`intakes/${intakeId}`);

export const editIntake = (intakeId: string, body: any): Promise<Intake> => api.patch(`intakes/${intakeId}`, body);

export const deleteIntake = (intakeId: string): Promise<void> => api.delete(`intakes/${intakeId}`);

export const editOrganisationForm = (formId: string, body: any): Promise<OrganisationForm> =>
  api.patch(`forms/${formId}`, body);

export const importIntakeInBulk = (
  organisationId: string,
  body: { items: IntakeCSVData[] },
): Promise<ArrayResponseType<Intake>> => api.post(`intakes/bulk/organisations/${organisationId}`, body);

export const fetchReferenceQuestionTypes = (): Promise<ArrayResponseType<ReferenceQuestionType>> =>
  api.get('forms/questions/fields');
