import { NotesType, SortDirection } from 'data/enums';

export const activitiesKeys = {
  all: () => ['activities'] as const,
  list: (trajectoryId?: string, params?: string) => [...activitiesKeys.all(), 'list', trajectoryId, params] as const,
  detail: (id: string) => [...activitiesKeys.all(), id] as const,
};

export const adminsKeys = {
  all: () => ['admins'] as const,
};

export const questionnairesKeys = {
  all: () => ['questionnaire'] as const,
  answers: (questionnaireContainerId?: string) => [...questionnairesKeys.all(), questionnaireContainerId, 'answers'],
  lists: () => [...questionnairesKeys.all(), 'list'] as const,
  list: (filters: string) => [...questionnairesKeys.lists(), { filters }] as const,
  details: () => [...questionnairesKeys.all(), 'detail'] as const,
  detail: (id: string) => [...questionnairesKeys.details(), id] as const,
  maxPrice: () => [...questionnairesKeys.all(), 'maxPrice'] as const,
};

export const templatesKeys = {
  all: () => ['template'] as const,
  lists: () => [...templatesKeys.all(), 'list'] as const,
  list: (filters: string) => [...templatesKeys.lists(), { filters }] as const,
  adminList: (filters: string) => [...templatesKeys.lists(), 'admin', { filters }] as const,
  details: () => [...templatesKeys.all(), 'detail'] as const,
  adminDetail: (id: string) => [...templatesKeys.details(), 'admin', id] as const,
  detail: (id: string) => [...templatesKeys.details(), id] as const,
  variables: (type: string) => [...templatesKeys.all(), 'variables', type] as const,
};

export const notesKeys = {
  all: () => ['notes'] as const,
  record: (recordId?: string, noteType = NotesType.TRAJECTORY, sortDirection?: SortDirection) =>
    [...notesKeys.all(), 'record', recordId, noteType, sortDirection] as const,
  trajectory: (trajectoryId?: string) => [...notesKeys.all(), 'trajectory', trajectoryId] as const,
  session: (sessionId: string) => [...notesKeys.all(), 'session', sessionId] as const,
  interaction: (interactionId: string) => [...notesKeys.all(), 'interaction', interactionId] as const,
};

export const recordKeys = {
  all: () => ['records'] as const,
  list: (filters: string, userId: string) => [...recordKeys.all(), filters, userId] as const,
  basic: (filters: string, userId: string) => [...recordKeys.list(filters, userId), 'basic'] as const,
  detail: (id?: string) => ['record', id] as const,
  documents: (filters: string, recordId: string, userId: string) => [
    ...recordKeys.list(filters, recordId),
    'documents',
    userId,
  ],
  organization: (orgId: string, filters: string) => ['record', 'organization', orgId, filters] as const,
  registrations: (recordId: string, query?: string) => ['intakes', 'record', recordId, query] as const,
};

export const recordRolesKeys = {
  all: (recordId: string, filter?: string) => ['recordRoles', recordId, filter],
  detail: (recordId: string, roleId: string) => [...recordRolesKeys.all(recordId), roleId] as const,
};

export const trajectoryKeys = {
  all: () => ['trajectories'] as const,
  detail: (id?: string) => ['trajectory', id] as const,
  clientAll: (recordId?: string) => [...trajectoryKeys.all(), 'client', recordId] as const,
  clientPast: (recordId: string) => [...trajectoryKeys.clientAll(recordId), 'past'] as const,
  recordAll: (recordId: string, query: string) => [...trajectoryKeys.all(), 'record', recordId, query] as const,
};

export const sessionKeys = {
  all: () => ['sessions'] as const,
  detail: (sessionId: string) => [...sessionKeys.all(), sessionId] as const,
};

export const locationKeys = {
  all: (userId: string) => ['locations', userId] as const,
  list: (userId: string) => [...locationKeys.all(userId), 'list'] as const,
};

export const contactKeys = {
  all: () => ['contacts'] as const,
  list: () => [...contactKeys.all(), 'list'] as const,
};

export const interactionKeys = {
  all: () => ['interactions'] as const,
  detail: (interactionId: string) => [...interactionKeys.all(), interactionId] as const,
};

export const interactionActivitiesKeys = {
  all: () => ['interactionActivities'] as const,
  list: (filters: string) => [...interactionActivitiesKeys.all(), filters] as const,
  detail: (activityId: string) => [...interactionActivitiesKeys.all(), activityId] as const,
};

export const physicalInteractionKeys = {
  all: () => ['physicalInteractions'] as const,
  detail: (interactionId: string) => [...physicalInteractionKeys.all(), interactionId] as const,
};

export const syncInteractionKeys = {
  all: () => ['syncInteractions'] as const,
  detail: (interactionId: string) => [...syncInteractionKeys.all(), interactionId] as const,
};

export const videoInteractionKeys = {
  all: () => ['videoInteractions'] as const,
  detail: (interactionId: string) => [...videoInteractionKeys.all(), interactionId] as const,
};

export const tasksKeys = {
  all: () => ['task'] as const,
  lists: () => [...tasksKeys.all(), 'list'] as const,
  list: (filters: string) => [...tasksKeys.lists(), { filters }] as const,
  details: () => [...tasksKeys.all(), 'details'] as const,
  detail: (taskId: string) => [...tasksKeys.details(), taskId] as const,
  learnWorlds: (id: string) => [...tasksKeys.all(), id, 'learnworlds'] as const,
};

export const tagsKeys = {
  all: () => ['tag'] as const,
  lists: () => [...tagsKeys.all(), 'list'] as const,
  list: (filters: string) => [...tagsKeys.lists(), { filters }] as const,
};

export const clientKeys = {
  all: () => ['clients'] as const,
  list: (filters) => [...clientKeys.all(), 'list', { filters }] as const,
  details: (clientId?: string) => [...clientKeys.all(), clientId] as const,
  confirmation: (clientId?: string) => [...clientKeys.all(), clientId, 'confirmation'],
};

export const affiliateKeys = {
  all: (clientId: string, filters: string) => ['affiliates', clientId, filters] as const,
  details: (affiliateId: string) => ['affiliate', affiliateId] as const,
};

export const fileKeys = {
  details: (fileId: string) => ['file', fileId] as const,
  link: (fileId: string) => [...fileKeys.details(fileId), 'link'] as const,
  activity: (fileId: string) => [...fileKeys.details(fileId), 'activity'] as const,
  activityLink: (fileId: string) => [...fileKeys.activity(fileId), 'url'] as const,
  session: (fileId: string) => [...fileKeys.details(fileId), 'session'] as const,
  sessionLink: (fileId: string) => [...fileKeys.session(fileId), 'url'] as const,
  taskActivity: (fileId: string) => [...fileKeys.details(fileId), 'taskActivity'] as const,
  taskActivityLink: (fileId: string) => [...fileKeys.taskActivity(fileId), 'url'] as const,
  note: (fileId: string) => [...fileKeys.details(fileId), 'note'] as const,
  noteLink: (fileId: string) => [...fileKeys.note(fileId), 'url'] as const,
  portfolioPublicLink: (fileId: string, resourceId: string) =>
    [...fileKeys.details(fileId), 'portfolioPublic', resourceId, 'url'] as const,
};

export const signatureKeys = {
  all: (filters: string) => ['signatures', filters] as const,
};

export const userKeys = {
  all: () => ['users'] as const,
  details: (userId: string) => ['user', userId] as const,
  resend: (userId: string) => [...userKeys.details(userId), 'resend'] as const,
  profile: () => [...userKeys.all(), 'profile'] as const,
  learnworld: () => [...userKeys.all(), 'learnworld'] as const,
  connections: (userId: string, filters: string) => ['connections', userId, { filters }] as const,
  roles: (userId: string, filters: string) => ['roles', userId, { filters }] as const,
  resetPasswordTokenStatus: (token: string) => ['reset-password-token', token] as const,
};

export const documentKeys = {
  details: (documentId: string) => ['document', documentId] as const,
  loopVariables: (recordId: string, params: string) => ['loop-variables', recordId, params] as const,
};

export const questionKeys = {
  all: () => ['questionnaire-container'] as const,
  list: (containerId: string) => [...questionKeys.all(), containerId] as const,
  question: (containerId: string, questionNumber: number) =>
    [...questionKeys.all(), containerId, questionNumber] as const,
  answers: (questionnaireId: string) => ['questionnaire-answers', questionnaireId] as const,
};

export const protocolsKeys = {
  all: () => ['protocols'] as const,
  lists: () => [...protocolsKeys.all(), 'list'] as const,
  list: (filters: string) => [...protocolsKeys.lists(), { filters }] as const,
  trajectory: (trajectoryId?: string) => [...protocolsKeys.all(), 'trajectory', trajectoryId] as const,
  trajectoryQuestionnaires: (trajectoryId?: string) =>
    [...protocolsKeys.trajectory(trajectoryId), 'questionnaires'] as const,
  details: () => [...protocolsKeys.all(), 'details'] as const,
  detail: (protocolId?: string) => [...protocolsKeys.details(), protocolId] as const,
  sessions: (protocolId?: string, params?: string) =>
    [...protocolsKeys.detail(protocolId), 'sessions', params] as const,
};

export const chatKeys = {
  all: () => ['chats'] as const,
  lists: () => [...chatKeys.all(), 'list'] as const,
  list: (filters: string) => [...chatKeys.lists(), { filters }] as const,
  notifications: () => [...chatKeys.all(), 'notifications'] as const,
  details: () => [...chatKeys.all(), 'details'] as const,
  detail: (chatRoomId: string) => [...chatKeys.details(), chatRoomId] as const,
  record: (recordId: string) => [...chatKeys.details(), 'record', recordId] as const,
  messages: (params?: string) => ['messages', params] as const,
};

export const reportsKeys = {
  all: () => ['reports'] as const,
  lists: () => [...reportsKeys.all(), 'list'] as const,
  list: (filters: string) => [...reportsKeys.lists(), { filters }] as const,
  details: () => [...reportsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...reportsKeys.details(), id] as const,
  session: (sessionId: string) => [...reportsKeys.all(), 'session', sessionId] as const,
  shared: (sessionId: string) => [...reportsKeys.all(), 'shared', sessionId] as const,
  // calculations
  calculations: (reportId: string) => [...reportsKeys.detail(reportId), 'calculations'] as const,
  calculation: (reportId: string, calcId: string) => [...reportsKeys.calculations(reportId), calcId] as const,
  // norms
  norms: (reportId: string) => [...reportsKeys.detail(reportId), 'norms'] as const,
  norm: (reportId: string, normId: string) => [...reportsKeys.norms(reportId), normId] as const,
  // graphs
  graphs: (reportId: string) => [...reportsKeys.detail(reportId), 'graphs'] as const,
  graph: (reportId: string, graphId: string) => [...reportsKeys.graphs(reportId), graphId] as const,
  // structure
  blocks: (reportId: string) => [...reportsKeys.detail(reportId), 'blocks'] as const,
  block: (reportId: string, blockId: string) => [...reportsKeys.graphs(reportId), blockId] as const,
};

export const tokenKeys = {
  recordAccess: (token: string) => ['recordAccess', token] as const,
  clientInvite: (token: string) => ['clientInvite', token] as const,
  signUpInvite: (token: string) => ['signUpInvite', token] as const,
};

export const organizationKeys = {
  default: () => ['organizations'] as const,
  all: (filters: string) => ['organizations', filters] as const,
  detail: (id: string | undefined | null) => ['organization', id] as const,
  billingPortal: (id: string) => ['billingPortal', id] as const,
  organizationAdmin: (id: string | null) => ['organizationAdmin', id] as const,
  stripeSubscriptions: () => ['stripe-subscriptions'] as const,
  stripeLink: (id: string | null) => ['stripe-subscriptions', id] as const,
  subscriptionPortalLink: (priceId: string) => ['subscription-portal-link', priceId] as const,
  logo: (orgId?: string, logoId?: string) => ['organization-logo', logoId] as const,
  addons: () => ['addons'] as const,
  organisationAddons: (organisationId: string) => ['addons', organisationId],
  organisationAddonPrice: (organisationId: string, priceId: string) => ['addons', organisationId, 'price', priceId],
  organisationPrices: (organisationId: string) => [organizationKeys.detail(organisationId), 'prices'],
};

export const organizationMembersKeys = {
  all: () => ['members'] as const,
  organization: (orgId?: string) => [...organizationMembersKeys.all(), orgId] as const,
  query: (orgId?: string, query?: string) => [...organizationMembersKeys.organization(orgId), query] as const,
};

export const therapistKeys = {
  all: () => ['therapist'] as const,
  lists: () => [...therapistKeys.all(), 'list'] as const,
  list: (filters: string) => [...therapistKeys.lists(), { filters }] as const,
  details: () => [...therapistKeys.all(), 'detail'] as const,
  detail: (id: string) => [...therapistKeys.details(), id] as const,
};

export const usageKeys = {
  all: (id: string) => ['usages', id] as const,
  single: (id: string, month: string, year: string) => [...usageKeys.all(id), month, year],
};

export const recordInvitesKeys = {
  all: (userId: string, query: string) => ['invites', userId, query] as const,
  details: (inviteId: string) => ['invite', inviteId],
};

export const treatmentInfoKeys = {
  details: (id: string) => ['treatmentInfo', id] as const,
};

export const publisherKeys = {
  all: () => ['publishers'] as const,
  lists: () => [...publisherKeys.all(), 'list'] as const,
  list: (filters: string) => [...publisherKeys.lists(), { filters }] as const,
};

export const calendarKeys = {
  all: () => ['calendars'] as const,
  details: (id: string) => [...calendarKeys.all(), id] as const,
  export: () => [...calendarKeys.all(), 'exportURL'] as const,
  eventsAll: () => ['events'] as const,
  events: (filters: string, userId: string) => [...calendarKeys.eventsAll(), filters, userId] as const,
  externalEvents: (filters: string, calendarId: string) => ['externalEvents', filters, calendarId] as const,
  slot: (id: string) => ['custom', id] as const,
};

export const portfolioKeys = {
  all: () => ['portfolio'] as const,
  lists: () => [...portfolioKeys.all(), 'list'] as const,
  list: (filters: string) => [...portfolioKeys.lists(), { filters }] as const,
  details: () => [...portfolioKeys.all(), 'details'] as const,
  detail: (userId: string) => [...portfolioKeys.details(), userId] as const,
};

export const recordGroupsKeys = {
  all: () => ['recordGroups'] as const,
  lists: () => [...recordGroupsKeys.all(), 'list'] as const,
  list: (filters: string) => [...recordGroupsKeys.lists(), { filters }] as const,
  details: () => [...recordGroupsKeys.all(), 'details'] as const,
  detail: (groupId?: string) => [...recordGroupsKeys.details(), groupId] as const,
};

export const intakesKeys = {
  all: (query: string) => ['intakes', query] as const,
  organisationForm: (organisationId: string) => ['organisationForm', organisationId] as const,
  organisationFormQuestions: (organisationId: string, formId: string) =>
    [...intakesKeys.organisationForm(organisationId), 'questions', formId] as const,
  publicFormQuestions: (token: string) => ['public', 'questions', token] as const,
  details: (id: string) => [...intakesKeys.all(), 'details', id] as const,
  comments: (intakeId: string, params?: string) => ['comments', intakeId, params] as const,
  timeline: (intakeId: string, params?: string) => ['timeline', intakeId, params] as const,
  referenceTypes: () => ['referenceTypes'] as const,
};

export const eHealthBoxKeys = {
  therapistsList: (filters: string) => ['eHealthBox', { filters }] as const,
};

export const bornInBelgiumKeys = {
  detail: (recordId: string) => ['bornInBelgium', recordId] as const,
};

export const questionnaireGroupsKeys = {
  all: () => ['questionnaireGroups'] as const,
  list: (query: string) => [...questionnaireGroupsKeys.all(), 'list', query] as const,
  group: (groupId: string) => ['questionnaireGroup', groupId] as const,
};

export const todoKeys = {
  all: () => ['todos'] as const,
  lists: () => [...todoKeys.all(), 'list'] as const,
  list: (filters: string) => [...todoKeys.lists(), { filters }] as const,
  details: () => [...todoKeys.all(), 'details'] as const,
  detail: (todoId?: string) => [...todoKeys.details(), todoId] as const,
};
