export enum IntakesTableAccessor {
  ID = 'id',
  NAME = 'name',
  REGISTERED = 'registered',
  STATUS = 'status',
  ASSIGNEE = 'assignee',
  LABELS = 'labels',
  ORDER_INDEX = 'orderIndex',
  CREATED_AT = 'createdAt',
}
