import {
  Record,
  DocumentFile,
  CreateRecordFields,
  EditRecordFields,
  BaseFetchListParams,
  ArrayResponseType,
  DocumentLinkFields,
  EditRecordStatus,
  BasicRecord,
  RecordIntake,
} from 'data/types';
import { recordKeys, userKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from '@tanstack/react-query';
import { RecordIntakesTableAccessor } from 'app/components/app/therapist/records/page/record/registration/components/intakes-table/constants';

import queryString from 'qs';
import { AxiosError } from 'axios';

import { ClientSortKeys, FilePurpose, RecordRoles, SortDirection } from 'data/enums';
import { getStoredUserId } from 'data/utils/userStorage';
import * as api from '../actions-query';

export enum FilesSortKeys {
  title = 'title',
  owner = 'owner',
  createdAt = 'createdAt',
}
export interface IRecordsSearchParams extends BaseFetchListParams {
  sort: {
    [key in ClientSortKeys]?: SortDirection;
  };
  filters: {
    search?: string | null;
    active?: number;
    roles?: RecordRoles[];
  };
}

export interface IRecordsDocumentsParams {
  offset?: number;
  limit?: number;
  sort?: {
    [key: string]: SortDirection;
  };
  filters?: {
    search?: string | null;
  };
  purpose?: FilePurpose;
}

interface RecordIntakesParams {
  sort?: {
    [key in RecordIntakesTableAccessor]?: SortDirection;
  };
  limit?: number;
  offset?: number;
}

export const defaultSearchParams: IRecordsSearchParams = {
  sort: {
    [ClientSortKeys.LAST_NAME]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
  filters: {
    active: 1,
  },
};

export const defaultDocumentSearchParams: IRecordsDocumentsParams = {
  sort: {
    title: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
};

// eslint-disable-next-line default-param-last
export const useRecords = ({
  searchParams = defaultSearchParams,
  isOrgAdmin = false,
  disabled = false,
  isClient = false,
}: {
  searchParams?: IRecordsSearchParams;
  isOrgAdmin?: boolean;
  disabled?: boolean;
  isClient?: boolean;
}) => {
  const query = queryString.stringify(searchParams);

  const userId = getStoredUserId();
  return useQuery<ArrayResponseType<Record>, AxiosError>(
    recordKeys.list(query, userId),
    () => api.fetchRecords(userId, query, isClient),
    { enabled: !isOrgAdmin && !disabled },
  );
};

export const useBasicRecords = (
  searchParams: IRecordsSearchParams = defaultSearchParams,
  isOrgAdmin = false,
  disabled = false,
) => {
  const query = queryString.stringify(searchParams);

  const userId = getStoredUserId();
  return useQuery<ArrayResponseType<BasicRecord>, AxiosError>(
    recordKeys.basic(query, userId),
    () => api.fetchBasicRecords(userId, query),
    { enabled: !isOrgAdmin && !disabled },
  );
};

export const useOrganisationRecords = (orgId: string, searchParams: IRecordsSearchParams, isOrgAdmin?: boolean) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Record>, AxiosError>(
    recordKeys.organization(orgId, query),
    () => api.fetchOrganisationRecords(orgId, query),
    { enabled: isOrgAdmin },
  );
};

export const useRecord = ({ recordId, options, isAffiliate }: { recordId?: string; options?; isAffiliate?: boolean }) =>
  useQuery<Record, AxiosError>(recordKeys.detail(recordId), () => api.fetchRecord({ recordId, isAffiliate }), {
    enabled: !!recordId,
    refetchOnMount: false,
    ...options,
  });

export const useRecordDocuments = (recordId: string, searchParams?: IRecordsDocumentsParams) => {
  const userId = getStoredUserId();
  const query = queryString.stringify(searchParams || defaultDocumentSearchParams);
  return useQuery<ArrayResponseType<DocumentFile>, AxiosError>(recordKeys.documents(query, recordId, userId), () =>
    api.fetchRecordDocuments(recordId, userId, query),
  );
};

export const useLinkDocument = (
  searchParams?: IRecordsDocumentsParams,
): UseMutationResult<unknown, any, { recordId: string; fields: DocumentLinkFields }, unknown> => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();
  return useMutation(({ recordId, fields }) => api.linkDocumentToRecord(recordId, userId, fields), {
    onSuccess: (_, variables) => {
      const query = queryString.stringify(searchParams || defaultDocumentSearchParams);
      queryClient.invalidateQueries(recordKeys.documents(query, variables.recordId, userId));
    },
  });
};

export const useEditRecord = (): UseMutationResult<
  unknown,
  any,
  { recordId: string; fields: EditRecordFields },
  unknown
> => {
  const queryClient = useQueryClient();

  const userId = getStoredUserId();
  return useMutation(({ recordId, fields }) => api.editRecord(recordId, fields), {
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries(recordKeys.list(query, userId));
      queryClient.invalidateQueries(recordKeys.detail(variables.recordId));
    },
  });
};

export const useEditRecordStatus = (): UseMutationResult<
  unknown,
  any,
  { recordId: string; fields: EditRecordStatus },
  unknown
> => {
  const queryClient = useQueryClient();

  const userId = getStoredUserId();
  return useMutation(({ recordId, fields }) => api.editRecordStatus(recordId, fields), {
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries(recordKeys.list(query, userId));
      queryClient.invalidateQueries(recordKeys.detail(variables.recordId));
    },
  });
};

export const useDeleteRecord = (userId: string): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((recordId) => api.deleteRecord(recordId), {
    onSuccess: () => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries(recordKeys.list(query, userId));
      queryClient.invalidateQueries(userKeys.profile());
    },
  });
};

export const useCreateRecord = (): UseMutationResult<
  unknown,
  any,
  { therapistId: string; fields: CreateRecordFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ therapistId, fields }) => api.createRecord(therapistId, fields), {
    onSuccess: (_, variables) => {
      const query = queryString.stringify(defaultSearchParams);
      queryClient.invalidateQueries(recordKeys.list(query, variables.therapistId));
    },
  });
};

export const useRecordRegistrations = (recordId: string, params?: RecordIntakesParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<RecordIntake>, AxiosError>(
    recordKeys.registrations(recordId, query),
    () => api.fetchRecordRegistrations(recordId, query),
    {
      enabled: !!recordId,
    },
  );
};
